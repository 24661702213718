import { createSignalRContext } from 'react-signalr';

import { ITeamMemberLocation } from '~/services/api/network/types';
import { useStoreActions, useStoreState } from '~/store/hooks';

export const LocationTrackerContext = createSignalRContext({
  shareConnectionBetweenTab: true,
});

export const useLocationTracker = (): void => {
  const { id } = useStoreState(state => state.user.current);
  const { updateTeamMemberLocation, removeTeamMemberLocation, setTeamMembersLocationOffline } =
    useStoreActions(state => state.network);
  // watch team members location change
  LocationTrackerContext.useSignalREffect(
    'UserLocationUpdated',
    (...args: ITeamMemberLocation[]) => {
      const [data] = args;
      const isValidTeamMemberLocationData = 'coordinates' in data && 'user' in data;

      const isCurrentUser = data?.user?.id === id;

      if (isValidTeamMemberLocationData && !isCurrentUser) {
        updateTeamMemberLocation(data);
      }
    },
    [],
  );

  // remove team member from the map because he stopped sharing location
  LocationTrackerContext.useSignalREffect(
    'UserLocationSharingStopped',
    (...args: (ITeamMemberLocation & { id: number })[]) => {
      const [data] = args;
      const isValidTeamMemberData = 'id' in data;

      if (isValidTeamMemberData) {
        removeTeamMemberLocation(data.id);
      }
    },
    [],
  );

  // change team member online status when he logs out
  LocationTrackerContext.useSignalREffect(
    'UserLoggedOut',
    (...args: (ITeamMemberLocation & { id: number })[]) => {
      const [data] = args;

      const isValidTeamMemberData = 'id' in data;

      if (isValidTeamMemberData) {
        setTeamMembersLocationOffline(data.id);
      }
    },
    [],
  );
};
