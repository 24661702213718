import { ReactElement, ReactNode, useEffect, useState } from 'react';

import {
  getAccessToken,
  getRefreshToken,
  getUserData,
  setTokens,
} from '~/services/localStorage/auth';
import { useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';
import {
  LocationTrackerContext,
  useLocationTracker,
} from '~/ui/pages/Network/hooks/useLocationTracker';
import isDev from '~/utils/isDev';
import useIsOnline from '~/utils/network/useIsOnline';
import api from '../api';
import { locationTrackerBaseUrl } from '../api/network';

interface IProps {
  children: ReactNode | ReactNode[];
}
let tries = 0;

const LocationTrackerProvider = ({ children }: IProps): ReactElement => {
  const { teamId, roleId, clinicId } = getUserData() || {};
  const { isSuperAdmin, isProductOwner } = useRole();

  const isConnectionDisabled = isSuperAdmin || isProductOwner;

  const { current } = useStoreState(state => state.user);
  const isOnline = useIsOnline();

  const [token, setToken] = useState<string | null>(null);

  useLocationTracker();

  const handleRefreshTokens = () => {
    if (!roleId) {
      return null;
    }

    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    return api.auth
      .refreshToken({
        teamId,
        roleId,
        clinicId,
        accessToken,
        refreshToken,
      })
      .then(({ data }) => {
        setTokens(data);
        setToken(data.accessToken);
        tries = 0;
      })
      .catch(e => {
        if (isDev) {
          // eslint-disable-next-line no-console
          console.log('SignalRContainer refreshToken error: ', JSON.stringify(e));
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (isOnline) {
      tries = 0;
      setToken(null);
    }
  }, [isOnline]);

  useEffect(() => {
    const accessToken = getAccessToken();

    if (accessToken && !token && current) {
      setToken(accessToken);
    } else if (!accessToken) {
      setToken(null);
    }
  }, [token, current]);

  const connectionCloseDelay = () =>
    new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

  const handleError = (error?: Error) => {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.log('error: ', JSON.stringify(error));
    }
    if (tries >= 5) {
      return connectionCloseDelay();
    }
    tries += 1;
    return handleRefreshTokens();
  };

  const handleConnection = () => {
    tries = 0;
  };

  if (!locationTrackerBaseUrl || locationTrackerBaseUrl.includes('stage')) {
    return <>{children}</>;
  }

  return (
    <LocationTrackerContext.Provider
      connectEnabled={!!token && !isConnectionDisabled && isOnline}
      withCredentials={false}
      dependencies={[token]}
      transport={2}
      url={locationTrackerBaseUrl.replace('api/v1/', `hubs/location?access_token=${token}`)}
      logger={6}
      onBeforeClose={connectionCloseDelay}
      onOpen={handleConnection}
      onReconnect={handleConnection}
      onError={handleError}
    >
      {children}
    </LocationTrackerContext.Provider>
  );
};
export default LocationTrackerProvider;
